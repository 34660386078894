import {Typography} from "@mui/material";

type DateProp = {
    date: number
};

const days = ["Mon.", "Tue.", "Wed.", "Thu.", "Fri.", "Sat.", "Sun."]
const pad = (a: number) => a > 10 ? a.toString() : "0" + a.toString();

export default function DatePretty(props: DateProp) {
    const date = new Date(props.date);
    const stringify = days[date.getDay()] + " " +  pad(date.getDate()) + "/" + pad(date.getMonth() + 1) + " at " + pad(date.getHours()) + ":" + pad(date.getMinutes())
    return <Typography variant="caption">{stringify}</Typography>
}