import {List, Stack} from "@mui/material";
import React, {useContext, useState} from "react";

import IncomeMessage from "../components/income"
import OutcomeMessage from "./outcome";
import Writer from "./writer";
import {DepsContext} from "../App";
import {makeStyles} from "@mui/styles";
import {Message} from "../domain/message";


const useStyles = makeStyles({
    root: {
        width: '100%'
    },
    list: {
        width: '100%',
        overflow: 'auto',
        maxHeight: '85vh'
    }
})

export default function Conversation() {

    const services = useContext(DepsContext);
    const classes = useStyles();
    const [messages, setMessages] = useState<Array<Message>>([])
    const [sender, setSender] = useState<string>("");


    const update = (address: string) => setMessages(services.message.getCorrespondence(address));
    services.message.notify(() => update(sender));
    services.event.onTopicChange((addr) => {
        setSender(addr);
        update(addr);
    })

    return (
        <Stack direction="column" justifyContent="flex-end" className={classes.root}>

            <List className={classes.list}>
                {messages.map(mes => {
                    if (mes.sender === sender) {
                            return <IncomeMessage message={mes}/>
                    } else {
                        return <OutcomeMessage message={mes.content} date={mes.date}/>
                    }
                })}

            </List>
            <Writer/>
        </Stack>
    )
}