import React, {createContext} from 'react';
import './App.css';
import Home from "./containers/Home";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {CssBaseline} from "@mui/material";
import {MessageService} from "./service/message";
import {UserService} from "./service/user";
import {EventsService} from "./service/events";

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        background: {default: '#2c2c2c'},
        primary: {
            main: '#e58537',
        },
        secondary: {
            main: 'rgb(109,143,233)',
        },
    },
});

const user = new UserService();
const message = new MessageService(user);
const event = new EventsService();
const services = {
    message: message,
    user: user,
    event: event
}

export const DepsContext = createContext(services);

class App extends React.Component<any, any> {
    render() {
        return (
            <ThemeProvider theme={darkTheme}>
                <DepsContext.Provider value={services}>
                    <CssBaseline/>
                    <Home/>
                </DepsContext.Provider>
            </ThemeProvider>
        );
    }
}

export default App;
