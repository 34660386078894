export class EventsService {
    private topics: Array<(address: string) => void> = []

    onTopicChange(callable: (address: string) => void) {
        this.topics.push(callable);
    }

    changeTopic(address: string) {
        this.topics.forEach(c => c(address));
    }
}