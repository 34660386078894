import {useEffect, useRef} from "react";

type AvatarAddressProps = {
    address: string
    size: number
}

export default function AvatarAddress(props: AvatarAddressProps) {
    const canvasRef = useRef(null)

    useEffect(() => {
        const canvas: any = canvasRef.current
        if (canvas === null) {
            return;
        }
        const context = canvas.getContext('2d')
        //Our first draw
        context.beginPath();

        let seed = props.address.toUpperCase();
        const l = props.size / 3;
        for(let i=0; i<9; i++) {
            context.fillStyle = '#' + seed.substring(seed.length-6);
            console.log(context.fillStyle);
            context.fillRect(l * (i % 3), l * Math.floor(i / 3), l, l);

            seed = seed.substring(0, seed.length - 4);
        }
    }, [props.address, props.size])

    return <canvas ref={canvasRef} width={props.size} height={props.size} style={{borderRadius: '50%'}}/>
}