import {Message} from "../domain/message";
import {UserService} from "./user";
import {Topic} from "../domain/topic";

export class MessageService {
    private messages: Array<Message> = [
        new Message("0xb2C7c59fB26932A673993a85D0FA66c6298f8F01", "0x88d5b7793626dd116B8449dC249F43dA58a3Bd86", "Hi, are you interested to sell your NFT ?"),
        new Message("0xdAC17F958D2ee523a2206206994597C13D831ec7", "0x88d5b7793626dd116B8449dC249F43dA58a3Bd86", "How are you, I don't see you in decentraland yesterday ?"),
    ];
    private callables: Array<(mes: Message) => void> = []

    private randoms = ["Hey", "What's up ?", "Do you have information about your NFT ?"]

    constructor(private user: UserService) {
        setInterval(() => {

            const topics = this.getLatest();
            const i = Math.floor(Math.random() * 1000 % topics.length);
            const j = Math.floor(Math.random() * 1000 % this.randoms.length);

            this.send(new Message(topics[i].sender, this.getUserAddress(), this.randoms[j]));

        }, 50000)
    }

    public sendTo(content: string, to: string) {
        this.send(new Message(this.getUserAddress(), to, content));
    }

    public send(message: Message) {
        this.messages.push(message);
        this.callables.forEach(c => c(message));
        console.log(message);
    }

    private getUserAddress(): string {
        return this.user.getAddress();
    }

    public notify(callable: (mes: Message) => void) {
        this.callables.push(callable);
    }

    public getCorrespondence(address: string): Array<Message> {
        return this.messages.filter(mes => mes.receiver === address || mes.sender === address).sort((a, b) => a.date - b.date);
    }

    public getLatest(): Array<Topic> {
        const latests = new Map<string, Topic>();

        for (let mes of this.getCorrespondence(this.getUserAddress())) {
            const correspondence = mes.sender !== this.getUserAddress() ? mes.sender : mes.receiver;

            if (latests.get(correspondence) === undefined || latests.get(correspondence)!.date < mes.date) {
                latests.set(correspondence, new Topic(correspondence, mes.date, mes.content));
            }
        }
        return Array.from(latests.values()).sort((a, b) => b.date - a.date);
    }
}