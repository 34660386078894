import React from "react";
import {ListItem, Paper} from "@mui/material";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import {makeStyles} from "@mui/styles";
import DatePretty from "./date";
import AvatarAddress from "./avatar";
import {Message} from "../domain/message";

const useStyles = makeStyles((theme: any) => ({
    root: {
        width: "70%"
    },
    message: {
        margin: '0 10px 0 0',
        padding: '10px',
        background: theme.palette.primary.main,
        color: "white"
    }
}));

type IncomeMessageProps = {
    message: Message
}

export default function IncomeMessage(props: IncomeMessageProps) {

    const classes = useStyles();


    return (
        <ListItem alignItems="flex-start" className={classes.root}>
            <ListItemAvatar>
                <AvatarAddress address={props.message.sender} size={40}/>
            </ListItemAvatar>
            <ListItemText
                primary={<Paper elevation={1} className={classes.message}>{props.message.content}</Paper>}
                secondary={<DatePretty date={props.message.date}/>}
            />
        </ListItem>
    );

}