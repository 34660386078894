import React, {useContext, useEffect, useState} from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';
import {Box, IconButton, TextField} from "@mui/material";
import {Add} from "@mui/icons-material";
import {DepsContext} from "../App";
import {makeStyles} from "@mui/styles";
import {Topic} from "../domain/topic";
import DatePretty from "./date";
import AvatarAddress from "./avatar";


const useStyles = makeStyles({
    root: {
        width: '450px',
        background: 'black'
    },
    primary: {
        display: 'inline',
        fontFamily: "monospace"
    },
    secondary: {
        display: 'inline'
    },
    input: {
        width: '100%'
    }
})

export default function Topics() {

    const services = useContext(DepsContext);
    const classes = useStyles();
    const [latests, setLatests] = useState<Array<Topic>>([]);
    const [newAddress, setNewAddress] = useState<string>('')
    const [selected, setSelected] = useState<string>('');

    const update = () => setLatests(services.message.getLatest());
    useEffect(update, [services.message]);
    services.message.notify(update);

    const add = () => {
        services.event.changeTopic(newAddress);
        setSelected(newAddress);
        setNewAddress("");
        latests.push(new Topic(newAddress, Date.now(), "..."));
        setLatests(latests);
    }

    const changeTopic = (add: string) => {
        setSelected(add);
        services.event.changeTopic(add);
    }

    return (
        <Box className={classes.root}>
            <ListItem alignItems="flex-start">
                <ListItemAvatar>
                    <IconButton color="primary"
                                aria-label="upload picture"
                                component="span"
                                onClick={add}
                    >
                        <Add/>
                    </IconButton>
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <TextField
                            id="outlined-basic"
                            value={newAddress}
                            onChange={(e) => setNewAddress(e.target.value)}
                            onKeyPress={(e) => {
                                console.log(e);
                                if (e.code !== 'Enter') return;
                                e.preventDefault();
                                add();
                            }}
                            label="ETH Address"
                            variant="outlined"
                            className={classes.input}/>

                    }
                />
            </ListItem>
            <List>
                {latests.map(t => (
                    <>
                        <ListItem
                            alignItems="flex-start"
                            style={{background: t.sender === selected ? '#2c2c2c': 'none'}}
                            onClick={() => changeTopic(t.sender)}>
                            <ListItemAvatar>
                                <AvatarAddress address={t.sender} size={40}/>
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        className={classes.primary}
                                        component="span"
                                        variant="body2"
                                        color="text.primary">
                                        {t.sender}
                                    </Typography>
                                }
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            className={classes.secondary}
                                            component="span"
                                            variant="body2"
                                            color="text.primary">
                                            <DatePretty date={t.date}/> &nbsp; - &nbsp;
                                        </Typography>
                                        {t.latest}
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        <Divider variant="inset" component="li"/>
                    </>
                ))}
            </List>
        </Box>
    );
}