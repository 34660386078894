import React, {useContext, useState} from "react";
import {IconButton, Stack, TextField} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Send} from "@mui/icons-material";
import {DepsContext} from "../App";

const useStyles = makeStyles((theme: any) => ({
    root: {
        margin: "20px",
    },
    input: {
        width: '100%'
    }
}));

export default function Writer() {

    let services = useContext(DepsContext);
    const classes = useStyles();
    const [message, setMessage] = useState("");
    const [receiver, setReceiver] = useState<string>('');

    services.event.onTopicChange(addr => setReceiver(addr));

    const send = () => {
        services.message.sendTo(message, receiver);
        setMessage('')

    }

    return (
        <Stack direction="row" className={classes.root}>
            <TextField id="message"
                       label="Message"
                       variant="standard"
                       className={classes.input}
                       onChange={(e) => setMessage(e.target.value)}
                       onKeyPress={(e) => {
                           console.log(e);
                           if (e.code !== 'Enter') return;
                           e.preventDefault();
                           send();
                       }}
                       value={message}/>
            <IconButton
                onClick={() => send()}
                color="primary" aria-label="send" component="span" size="large">
                <Send />
            </IconButton>
        </Stack>
    );
}
