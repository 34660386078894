import React from "react";
import {ListItem, Paper} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import {withStyles} from "@mui/styles";
import DatePretty from "./date";

type OutcomeMessageProps = {
    message: string;
    date: number;
    classes: any;
};
type OutcomeMessageState = {

};

const styles = (theme: any) => ({
    root: {
        maxWidth: "70%",
        width: "auto",
        marginLeft: "30%",
        textAlign: "right" as const

    },
    message: {
        margin: '0 0 0 10px',
        padding: '10px',
        background: theme.palette.secondary.main,
        color: "white",
    },
    date: {

    }
});

class MyOutcomeMessage extends React.Component<OutcomeMessageProps, OutcomeMessageState> {

    constructor(public props: OutcomeMessageProps) {
        super(props);
    }

    render() {

        return (
            <ListItem alignItems="flex-start" className={this.props.classes.root}>
                <ListItemText
                    primary={<Paper elevation={1}  className={this.props.classes.message}>{this.props.message}</Paper>}
                    secondary={<DatePretty date={this.props.date}/>}
                />
            </ListItem>
        );
    }

}

const OutcomeMessage = withStyles(styles)(MyOutcomeMessage);
export default OutcomeMessage;