import React from "react";
import {Stack} from "@mui/material";
import NavBar from "../components/navbar";
import Topics from "../components/topics";
import Conversation from "../components/conversation";

type HomeProps = {};

type HomeState = {
    count: number; // like this
};

class Home extends React.Component<HomeProps, HomeState> {
    public constructor(props: HomeProps) {
        super(props);
    }

    render() {
        return (
            <Stack style={{height: '100vh'}}>
                <NavBar/>
                <Stack direction="row" style={{width: '100%', height: '100%'}}>
                    <Topics/>
                    <Conversation/>
                </Stack>
            </Stack>
        );
    }
}

export default Home;